<script>
import SoundPlayer from "../../components/SoundCertificates/SoundPlayer.vue";

export default {
  name: "SoundCertificates",
  components: {SoundPlayer}
}
</script>

<template>
  <div class="page-content py-5">
    <div class="container">
      <div class="page-content py-5">
        <div class="container">
            <sound-player></sound-player>
        </div>
      </div>
    </div>
  </div>
</template>

<style scoped>

</style>